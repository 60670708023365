<template>
  <div class="w-full pt-5">
    <div v-if="hasModuleAccess">
      <template>
        <div class="flex items-center">
          <div class="border-r border-romanSilver h-6 flex">
            <BackButton label="Back" @onClick="$router.back()" variant="secondary" class="ml-2" />
          </div>
          <h1 class="text-xl text-left font-extrabold mx-4">
            Employee Appraisal
          </h1>
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </template>
      <template v-if="isLoading">
        <div class="mt-20 h-full">
          <Loader size="xxs" :loader-image="false" />
        </div>
      </template>

      <template v-else>
        <template>
          <Card class="px-5 py-2 flex flex-col m-5">
            <div class="flex justify-between items-center m-1">
              <div class="flex gap-2 mb-2 font-bold text-sm leading-6 tracking-normal">
                <span class="uppercase text-romanSilver">Employee:</span>
                <span class="capitalize text-darkPurple">{{
                  employees[0].name
                }}</span>
              </div>
            </div>
            <div class="flex gap-5">
              <div class="flex-1 w-2/12 rounded-md">
                <img v-if="
                  employees[0].photo !== '' && employees[0].photo !== null
                " :src="employees[0].photo" alt="profile" style="border-radius: 8px" />
                <div style="height: 137px; width: 183px; border-radius: 5px"
                  class="text-blueCrayola border text-center font-black text-lg pt-14 mr-2" v-else>
                  {{ $getInitials(`${employees[0].name}`) }}
                </div>
              </div>
              <div class="flex flex-col flex-3 w-10/12">
                <div class="flex mb-2 w-full gap-2">
                  <div class="flex flex-1 p-3 shadow rounded-md gap-2 items-start">
                    <Icon class-name="text-romanSilver" size="xs" icon-name="calendar" />
                    <div class="flex flex-col tracking-wide font-bold">
                      <span class="text-xs text-carrotOrange uppercase">Designation</span>
                      <span class="text-sm text-jet uppercase">{{
                        employees[0].designation
                      }}</span>
                    </div>
                  </div>
                  <div class="flex flex-1 p-3 shadow rounded-md gap-2 items-start">
                    <Icon class-name="text-romanSilver" size="xs" icon-name="icon-department" />
                    <div class="flex flex-col tracking-wide font-bold">
                      <span class="text-xs text-blueCrayola uppercase">Function</span>
                      <span class="text-sm text-jet uppercase">{{
                        employees[0].function
                      }}</span>
                    </div>
                  </div>
                  <div class="flex flex-1 p-3 shadow rounded-md gap-2 items-start">
                    <Icon class-name="text-romanSilver" size="xs" icon-name="user" />
                    <div class="flex flex-col tracking-wide font-bold">
                      <span class="text-xs text-mediumSeaGreen uppercase">Line Manager</span>
                      <span class="text-sm text-jet uppercase whitespace-nowrap">{{ employees[0].manager }}</span>
                    </div>
                  </div>
                </div>
                <div class="flex mb-4 w-full gap-2">
                  <div class="flex flex-1 p-3 shadow rounded-md gap-2 items-start w-1/3">
                    <Icon class-name="text-romanSilver" size="xs" icon-name="icon-user-list" />
                    <div class="flex flex-col tracking-wide font-bold">
                      <span class="text-xs text-lite uppercase">Employee ID</span>
                      <span class="text-sm text-jet uppercase">{{
                        employees[0].employeeId
                      }}</span>
                    </div>
                  </div>
                  <div class="flex flex-2 p-3 shadow rounded-md gap-2 items-start w-2/3">
                    <Icon class-name="text-romanSilver" size="xs" icon-name="pin" />
                    <div class="flex flex-col tracking-wide font-bold">
                      <span class="text-xs text-flame uppercase">Office Location</span>
                      <span class="text-sm text-jet uppercase">{{
                        employees[0].office
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </template>
        <template>
          <div class="mx-5 mt-10">
            <Tab class="mt-6 mx-3" :tabs="tabsItems" :active-tab="activeTab" @currentTab="activateTab($event)"
              :border="true" />
            <div v-if="startAppraisal">
              <template v-if="!finalAppraisal">
                <div class="mt-20 h-full" v-if="loadingAppraisal">
                  <Loader size="xxs" :loader-image="false" />
                </div>

                <div class="w-full flex flex-col" v-else>
                  <Card class="p-5">
                    <div class="w-full flex flex-col">
                      <div class="w-full flex p-0 justify-between items-center">
                        <h6 class="font-bold text-lg leading-normal">
                          {{ activeTab }}
                        </h6>

                        <div @click="isOpenComments = true">
                          <div class="flex justify-start items-center gap-2 cursor-pointer">
                            <Icon size="xs" icon-name="comment-outline" />
                            <span class="leading-5 font-sm font-normal text-jet">Comment</span>
                          </div>
                        </div>
                      </div>
                      <div class="w-full flex flex-col font-black text-xs">
                        <div class="w-full flex px-3 py-1 my-3 justify-between align-center bg-cultured rounded-md">
                          <div class="w-1/2">
                            <p class="leading-5 tracking-wide uppercase text-romanSilver">
                              KPA Types
                            </p>
                          </div>
                          <div class="w-1/2 mr-16 flex justify-end gap-8">
                            <p class="leading-5 tracking-wide uppercase text-romanSilver">
                              Allocated Weight
                            </p>
                            <p class="leading-5 tracking-wide mr-1 uppercase text-romanSilver">
                              employee
                            </p>
                            <p class="leading-5 tracking-wide ml-2 uppercase text-romanSilver">
                              line manager
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="w-full h-auto flex flex-col" v-if="loaded">
                        <Accordion v-for="(kpaTypes, i) in templateKPATypes" :key="i" class="mx-3 mt-6" show-divider
                          :is-open="false">
                          <template v-slot:title>
                            <span class="font-bold">{{ kpaTypes.name }}</span>
                          </template>
                          <template v-slot:block>
                            <div class="flex justify-center items-center gap-2 w-3/7 mr-3">
                              <div class="font-bold bg-ghostWhite px-12 py-1 rounded-md w-38">
                                <span class="text-darkPurple text-xs font-black">
                                  {{ kpaTypes.weight }}%
                                </span>
                              </div>
                              <div class="font-bold bg-ghostWhite py-2 w-32 rounded-md uppercase">
                                <span class="text-xs w-full flex justify-center font-bold">
                                  {{
                                    getKPATotalSelfAppraisal(kpaTypes.name) ===
                                      ""
                                      ? "---"
                                      : getKPATotalSelfAppraisal(kpaTypes.name)
                                  }}
                                </span>
                              </div>
                              <div class="w-32 font-bold bg-ghostWhite py-2 rounded-md uppercase">
                                <span class="text-xs w-full flex justify-center font-bold">
                                  {{
                                    getKPATotalManagerAppraisal(
                                      kpaTypes.name
                                    ) === ""
                                      ? "---"
                                      : getKPATotalManagerAppraisal(
                                        kpaTypes.name
                                      )
                                  }}
                                </span>
                              </div>
                            </div>
                          </template>
                          <div class="w-full">
                            <ScrollContainer height="1000px">
                              <Table :headers="KPAHeader" :items="kpaTypes.kpas" :row-header="true" :has-footer="true">
                                <template v-slot:kpis>
                                  <div class="label flex" v-for="(n, rating) in Ratings" :key="rating">
                                    <div class="mr-2 ml-4 -mt-1 text-base font-semibold w-52">
                                      {{ rating }}
                                    </div>
                                  </div>
                                </template>
                                <template v-slot:selfRating>
                                  <div class="w-72 flex">
                                    <div class="ml-4 -mt-1 text-sm font-semibold w-3/4">
                                      Flag
                                    </div>
                                  </div>
                                </template>
                                <template v-slot:managerRating>
                                  <div class="w-72 flex">
                                    <div class="ml-4 -mt-1 text-sm font-semibold w-3/4">
                                      Flag
                                    </div>
                                  </div>
                                </template>
                                <template v-slot:item="{ item }">
                                  <div v-if="item.name" class="py-2">
                                    <div class="mr-2 ml-4 -mt-1 text-base w-52">
                                      {{ item.data.name }}
                                    </div>
                                  </div>
                                  <div v-else-if="item.objective">
                                    <div class="mr-2 ml-4 -mt-1 text-base w-52">
                                      {{ item.data.objective }}
                                    </div>
                                  </div>

                                  <div v-else-if="item.kpis" class="flex">
                                    <div class="label flex" v-for="(n, rating) in item.data.kpis" :key="rating">
                                      <div class="mr-2 ml-4 -mt-1 text-base w-52">
                                        {{ item.data.kpis[rating].criteria }}
                                      </div>
                                    </div>
                                  </div>

                                  <div v-else-if="item.weight">
                                    <div class="label">
                                      <div class="mr-2 ml-4 -mt-1 w-32 text-base font-black text-blueCrayola">
                                        {{ item.data.weight }}
                                      </div>
                                    </div>
                                  </div>

                                  <div v-else-if="item.selfRating">
                                    <div class="w-72 flex">
                                      <div class="ml-4 -mt-1 text-sm font-semibold w-3/4">
                                        <c-select placeholder="--Rating--" variant="w-full" class="mr-2 -ml-1 -mt-1"
                                          type="number" :disabled="true" :options="flagOptions"
                                          v-model="item.data.selfRating" />
                                      </div>
                                    </div>
                                  </div>

                                  <div v-else-if="item.managerRating">
                                    <div class="w-72 flex">
                                      <div class="ml-4 -mt-1 text-sm font-semibold w-3/4">
                                        <c-select placeholder="--Rating--" variant="w-full" class="mr-2 -ml-1 -mt-1"
                                          type="number" :disabled="true" :options="flagOptions"
                                          v-model="item.data.ratingId" />
                                      </div>
                                    </div>
                                  </div>

                                  <div v-else-if="item.outcome">
                                    <div class="label ml-6">
                                      <c-text placeholder="--Enter--" variant="w-full" class="mr-2 -ml-1 -mt-1 w-72"
                                        :disabled="true" v-model="item.data.outcome" />
                                    </div>
                                  </div>
                                </template>
                                <template v-slot:footer>
                                  <td />
                                  <td />
                                  <td />
                                  <td>
                                    <div class="w-full flex justify-end p-3 -ml-12">
                                      <span class="text-lg font-black text-end">
                                        Summary
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="w-full flex">
                                      <div class="w-3/4 ml-3 justify-end flex p-1 bg-ghostWhite">
                                        <span class="text-lg text-center font-black w-full" style="border-radius: 5px">
                                          {{
                                            getKPATotalSelfAppraisal(
                                              kpaTypes.name
                                            ) === ""
                                              ? "---"
                                              : getKPATotalSelfAppraisal(
                                                kpaTypes.name
                                              )
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="w-full flex">
                                      <div class="w-3/4 ml-3 flex p-1 bg-ghostWhite">
                                        <span class="text-lg text-center font-black w-full" style="border-radius: 5px">
                                          {{
                                            getKPATotalManagerAppraisal(
                                              kpaTypes.name
                                            ) === ""
                                              ? "---"
                                              : getKPATotalManagerAppraisal(
                                                kpaTypes.name
                                              )
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                </template>
                              </Table>
                            </ScrollContainer>
                          </div>
                        </Accordion>
                      </div>
                    </div>
                  </Card>
                  <div class="flex flex-col w-full" v-if="loaded">
                    <template>
                      <div
                        class="w-full flex p-5 shadow rounded-md my-2 justify-between items-center relative text-right whitespace-nowrap">
                        <span class="font-bold text-lg leading-5 text-left text-darkPurple tracking-wider">
                          Summary
                        </span>
                        <div class="absolute left-0 right-0 border border-solid border-gray-400 h-0 ml-36 mr-52" />
                        <div class="flex justify-end gap-10 bg-white relative px-5">
                          <div class="flex flex-col items-start leading-5">
                            <span class="text-gray-400 text-xs text-center font-black uppercase">Appraisal Flag</span>
                            <span class="font-semibold text-base text-darkPurple">{{ approvedScore }}</span>
                          </div>
                          <div class="flex flex-col items-start leading-5">
                            <span class="text-gray-400 text-xs text-center font-black uppercase">self-appraisal</span>
                            <span class="font-semibold text-base text-darkPurple">{{ selfAppraisalScore }}</span>
                          </div>
                          <div class="flex flex-col items-start leading-5">
                            <span class="text-gray-400 text-xs text-center font-black uppercase">line manager</span>
                            <span class="font-semibold text-base text-darkPurple">{{ lineManagerAppraisalScore }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </template>

              <template v-else>
                <div class="mt-20 h-full" v-if="loadingAppraisal">
                  <Loader size="xxs" :loader-image="false" />
                </div>

                <div class="w-full flex flex-col" v-else>
                  <Card class="p-5">
                    <div class="w-full flex flex-col">
                      <div class="w-full flex p-0 mt-2 justify-between items-center">
                        <h6 class="font-bold text-lg leading-normal">
                          {{ activeTab }}
                        </h6>
                        <div @click="isOpenComments = true">
                          <div class="flex justify-start items-center gap-2 cursor-pointer">
                            <Icon size="xs" icon-name="comment-outline" />
                            <span class="leading-5 font-sm font-normal text-jet">Comment</span>
                          </div>
                        </div>
                      </div>
                      <div class="w-full flex flex-col font-black text-xs mt-1">
                        <div class="w-full flex px-3 py-1 my-3 justify-between align-center bg-cultured rounded-md">
                          <div class="w-1/2">
                            <p class="leading-5 tracking-wide uppercase text-romanSilver">
                              KPA Types
                            </p>
                          </div>
                          <div class="w-1/2 mr-12 flex justify-end gap-8">
                            <p class="leading-5 tracking-wide mr-2 uppercase text-romanSilver">
                              Allocated Weight
                            </p>
                            <p class="leading-5 tracking-wide mr-2 uppercase text-romanSilver">
                              employee
                            </p>
                            <p class="leading-5 tracking-wide uppercase text-romanSilver">
                              line manager
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="w-full h-auto flex flex-col" v-if="loaded">
                        <Accordion v-for="(kpaTypes, i) in templateKPATypes" :key="i" class="mt-6" show-divider
                          :is-open="false">
                          <template v-slot:title>
                            <span class="font-bold">{{ kpaTypes.name }}</span>
                          </template>
                          <template v-slot:block>
                            <div class="flex justify-center items-center gap-2 w-3/7 mr-3">
                              <div class="font-bold bg-ghostWhite px-12 py-1 rounded-md w-38">
                                <span class="text-darkPurple text-xs font-black">
                                  {{ kpaTypes.weight }}%
                                </span>
                              </div>
                              <div class="font-black bg-ghostWhite py-2 w-32 rounded-md uppercase">
                                <span class="text-xs w-full flex justify-center font-black">
                                  {{
                                    getKPATotalScore(kpaTypes.kpas) === ""
                                      ? "---"
                                      : getKPATotalScore(kpaTypes.kpas)
                                  }}
                                </span>
                              </div>
                              <div class="w-32 font-black bg-ghostWhite py-2 rounded-md uppercase">
                                <span class="text-xs w-full flex justify-center font-bold">
                                  {{
                                    getKPATotalManagerScore(kpaTypes.kpas) ===
                                      ""
                                      ? "---"
                                      : getKPATotalManagerScore(kpaTypes.kpas)
                                  }}
                                </span>
                              </div>
                            </div>
                          </template>
                          <div class="w-full">
                            <ScrollContainer height="1000px">
                              <Table :headers="KPAHeader" :items="kpaTypes.kpas" :row-header="true" :has-footer="true">
                                <template v-slot:kpis>
                                  <div class="label flex" v-for="(n, rating) in Ratings" :key="rating">
                                    <div class="mr-2 ml-4 -mt-1 text-base font-semibold w-52">
                                      {{ rating }}
                                    </div>
                                  </div>
                                </template>

                                <template v-slot:selfRating>
                                  <div class="w-72 flex">
                                    <div class="mr-2 -mt-1 text-sm font-semibold w-1/2">
                                      Rating
                                    </div>
                                    <div class="mr-2 -mt-1 text-sm font-semibold w-1/2">
                                      Score
                                    </div>
                                  </div>
                                </template>

                                <template v-slot:item="{ item }">
                                  <div v-if="item.name" class="py-2">
                                    <div class="mr-2 ml-4 -mt-1 text-base w-52">
                                      {{ item.data.name }}
                                    </div>
                                  </div>
                                  <div v-else-if="item.objective">
                                    <div class="mr-2 ml-4 -mt-1 text-base w-52">
                                      {{ item.data.objective }}
                                    </div>
                                  </div>

                                  <div v-else-if="item.kpis" class="flex">
                                    <div class="label flex" v-for="(n, rating) in item.data.kpis" :key="rating">
                                      <div class="mr-2 ml-4 -mt-1 text-base w-52">
                                        {{ item.data.kpis[rating].criteria }}
                                      </div>
                                    </div>
                                  </div>

                                  <div v-else-if="item.weight">
                                    <div class="label">
                                      <div class="mr-2 ml-4 -mt-1 w-32 text-base font-black text-blueCrayola">
                                        {{ item.data.weight }}
                                      </div>
                                    </div>
                                  </div>

                                  <div v-else-if="item.selfRating">
                                    <div class="w-72 flex">
                                      <div class="mr-2 -mt-1 text-sm font-semibold w-1/2">
                                        <c-select placeholder="--Rating--" variant="w-full" class="mr-2 -ml-1 -mt-1"
                                          type="number" :disabled="true" :options="ratingOptions"
                                          v-model="item.data.selfRating" />
                                      </div>
                                      <div class="mr-2 -mt-1 text-sm font-semibold w-1/2">
                                        <c-text placeholder="--Score--" variant="w-full" class="mr-2 -ml-1 -mt-1"
                                          type="number" :disabled="true" v-model="item.data.selfScore" />
                                      </div>
                                    </div>
                                  </div>

                                  <div v-else-if="item.managerRating">
                                    <div class="w-72 flex">
                                      <div class="mr-2 -mt-1 text-sm font-semibold w-1/2">
                                        <c-select placeholder="--Rating--" variant="w-full" class="mr-2 -ml-1 -mt-1"
                                          type="number" :disabled="true" :options="ratingOptions"
                                          v-model="item.data.managerRating" />
                                      </div>
                                      <div class="mr-2 -mt-1 text-sm font-semibold w-1/2">
                                        <c-text placeholder="--Score--" variant="w-full" class="mr-2 -ml-1 -mt-1"
                                          type="number" :disabled="true" v-model="item.data.managerScore" />
                                      </div>
                                    </div>
                                  </div>

                                  <div v-else-if="item.outcome">
                                    <div class="label ml-6">
                                      <c-text placeholder="--Enter--" variant="w-full" class="mr-2 -ml-1 -mt-1 w-72"
                                        :disabled="true" v-model="item.data.outcome" />
                                    </div>
                                  </div>
                                </template>

                                <template v-slot:footer>
                                  <td />
                                  <td />
                                  <td />
                                  <td>
                                    <div class="w-full flex justify-end p-3 -ml-4">
                                      <span class="text-lg font-black text-end">
                                        Total Scores
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="w-full flex">
                                      <div class="w-1/2" />
                                      <div class="w-32 justify-end flex p-1 bg-ghostWhite">
                                        <span class="text-lg text-center font-black w-full" style="border-radius: 5px">
                                          {{
                                            getKPATotalScore(kpaTypes.kpas) ===
                                              ""
                                              ? "---"
                                              : getKPATotalScore(kpaTypes.kpas)
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td></td>
                                  <td>
                                    <div class="w-32 -ml-40 flex p-1 bg-ghostWhite">
                                      <span class="text-lg text-center font-black w-full" style="border-radius: 5px">
                                        {{
                                          getKPATotalManagerScore(
                                            kpaTypes.kpas
                                          ) === ""
                                            ? "---"
                                            : getKPATotalManagerScore(
                                              kpaTypes.kpas
                                            )
                                        }}
                                      </span>
                                    </div>
                                  </td>
                                </template>
                              </Table>
                            </ScrollContainer>
                          </div>
                        </Accordion>
                      </div>
                    </div>
                  </Card>

                  <div class="flex flex-col w-full" v-if="loaded">
                    <template>
                      <div
                        class="w-full flex p-5 shadow rounded-md my-2 justify-between items-center relative text-right whitespace-nowrap">
                        <span class="font-bold text-lg leading-5 text-left text-darkPurple tracking-wider">
                          Final Score
                        </span>
                        <div class="absolute left-0 right-0 border border-solid border-gray-400 h-0 ml-36 mr-52" />
                        <div class="flex justify-end gap-10 bg-white relative px-5">
                          <div class="flex flex-col items-start leading-5">
                            <span class="text-gray-400 text-xs text-center font-black uppercase">Approved Score</span>
                            <span class="font-semibold text-base text-darkPurple">{{ approvedScore }}</span>
                          </div>
                          <div class="flex flex-col items-start leading-5">
                            <span class="text-gray-400 text-xs text-center font-black uppercase">self-appraisal</span>
                            <span class="font-semibold text-base text-darkPurple">{{ selfAppraisalScore.toFixed(2)
                            }}</span>
                          </div>
                          <div class="flex flex-col items-start leading-5">
                            <span class="text-gray-400 text-xs text-center font-black uppercase">line manager</span>
                            <span class="font-semibold text-base text-darkPurple">{{
                              lineManagerAppraisalScore.toFixed(2) }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>

            <div class="w-full" v-if="appraisalMessage !== ''">
              <div class="w-full flex flex-col justify-center items-center mt-20">
                <icon icon-name="empty_agreement" size="l" style="width: 300px" />
                <div class="w-1/2 text-base text-center">
                  {{ appraisalMessage }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>

      <template>
        <RightSideBar v-if="isOpenComments" @submit="sendComment" @close="isOpenComments = false"
          button-class="bg-dynamicBackBtn text-white">
          <template v-slot:title>
            <p class="text-darkPurple font-bold text-lg leading-5 p-0">
              Comments
            </p>
          </template>
          <div class="w-full flex">
            <div class="flex flex-col w-full" style="min-height: 70vh">
              <CommentsBox :comments="allComments" @markRead="markAsRead($event)" :loading="loadComments" />
              <div class="-mb-12" style="margin-top: auto">
                <CTextarea placeholder="--Message--" variant="primary" class="mt-2" value="" :row="8" :height="90"
                  :col="30" :input="null" v-model="comment" />
              </div>
            </div>
          </div>
        </RightSideBar>
      </template>
    </div>
    <div v-else>
      <ErrorComponent />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import ScrollContainer from "@/components/ScrollContainer";
import Loader from "@/components/Loader";
import Accordion from "@/components/Accordion";
import Table from "@/components/Table";
import Icon from "@/components/Icon";
import RightSideBar from "@/components/RightSideBar";
import CommentsBox from "@/components/commentsBox";
import paramsMixin from "@/utilities/paramsMixin";
import timeFormatMixin from "@/utilities/timeFormatMixin";
import ErrorComponent from "@/modules/Admin/error403";

export default {
  name: "PerformanceEmployeeAppraisals",
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    Tab,
    ErrorComponent,
    Breadcrumb,
    BackButton,
    CSelect,
    CommentsBox,
    ScrollContainer,
    CText,
    Loader,
    Card,
    Accordion,
    Table,
    Icon,
    CTextarea,
    RightSideBar,
  },
  data() {
    return {
      hasModuleAccess: false,
      openModal: false,
      isLoading: true,
      loaded: false,
      isOpenComments: false,
      loadComments: true,
      isManager: false,
      loadingAppraisal: true,
      tabsItems: [],
      activeTab: `${this.$route.params.cycle}`,
      KPAHeader: [
        { title: "KPA", value: "name" },
        { title: "Objective", value: "objective" },
        { title: "KPI", value: "kpis" },
        { title: "Weight (%)", value: "weight" },
      ],
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Appraisals",
          href: "performance-appraisals",
          id: "PerformanceAppraisals",
        },
        {
          disabled: false,
          text: "Employee Appraisal",
          href: "employee-appraisal",
          id: "EmployeeAppraisal",
        },
      ],
      startAppraisal: false,
      appraisalStartDate: "",
      appraisalEndDate: "",
      appraisalMessage: "",
      appraisalEnd: false,
      templateKPATypes: [],
      Ratings: [],
      ratingOptions: [],
      flagOptions: [],
      employeeAppraisals: [],
      selfAppraisalScore: "",
      lineManagerAppraisalScore: "",
      approvedScore: "",
      appraisalStatus: "",
      appraisalFlag: "",
      appraisalState: "",
      appraisalAgreed: "",
      checkEditAppraisal: false,
      disableAppraisal: false,
      appraisalDisplay: "",
      employee: false,
      finalAppraisal: false,
      cyclesArr: [],
      allComments: [],
      comment: "",
      employees: [],
      payload: {
        employeeAgreementId: "",
        orgId: null,
        goalId: null,
        year: "",
        employeeId: "",
        appraisal_status: "",
        status: "draft",
        cycleId: "",
        appraisalFor: "",
        appraisalBy: "",
        share: false,
        reviewType: "",
        kpa_types: [],
      },
    };
  },

  methods: {
    activateTab(tab) {
      const tabIndex = this.tabsItems.indexOf(tab, 0);

      if (tabIndex === this.tabsItems.length - 1) {
        this.finalAppraisal = true;
        this.payload.reviewType = "final_review";
      } else {
        this.finalAppraisal = false;
        this.payload.reviewType = "mid_review";
      }

      this.cyclesArr.filter((cycle) => {
        if (cycle.name === tab) {
          this.payload.cycleId = cycle.id;
        }
        return {};
      });

      this.employeeAppraisals = [];
      this.templateKPATypes = [];
      this.loaded = false;
      this.loadingAppraisal = true;
      this.startAppraisal = false;
      this.appraisalMessage = "";
      this.appraisalEnd = false;

      this.$_getAppraisalCycle(this.appraisalQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;

        appraisal[0].cycles.map((cycle) => {
          this.cyclesArr.push({
            id: cycle.id,
            name: cycle.name,
          });

          if (cycle.name === this.activeTab) {
            this.appraisalStartDate = this.setDates(cycle.appraisal_starts);
            this.appraisalEndDate = this.setDates(cycle.appraisal_ends);

            this.checkAppraisalStart();
          }
          return {};
        });
      });

      this.activeTab = tab;
    },

    changeRating(event, item, kpaTypes) {
      const ratingId = event.target.value;
      const { kpaId } = item.data;
      let sum = 0;
      this.$_calculateRatingScore(kpaId, ratingId).then((response) => {
        // eslint-disable-next-line no-param-reassign
        item.data.selfScore = response.data.data.score;
        kpaTypes.forEach((type) => {
          type.kpas.forEach((items) => {
            sum += Number(items.selfScore);
            return {};
          });
          return {};
        });
        this.selfAppraisalScore = sum.toFixed(2);
      });
    },

    changeManagerRating(event, item, kpaTypes) {
      const ratingId = event.target.value;
      const { kpaId } = item.data;
      let sum = 0;
      this.$_calculateRatingScore(kpaId, ratingId).then((response) => {
        // eslint-disable-next-line no-param-reassign
        item.data.managerScore = response.data.data.score;
        kpaTypes.forEach((type) => {
          type.kpas.forEach((items) => {
            sum += Number(items.managerScore);
            return {};
          });
          this.lineManagerAppraisalScore = sum.toFixed(2);
          return {};
        });
      });
    },

    getKPATotalScore(kpas) {
      let sum = 0;
      kpas.map((items) => {
        if (items.selfScore !== "") {
          sum += Number(items.selfScore);
        }
        return {};
      });

      if (sum >= 0) {
        return sum.toFixed(2);
      }
      return "";
    },

    getKPATotalManagerScore(kpas) {
      let sum = 0;
      kpas.map((items) => {
        if (items.managerScore !== "") {
          sum += Number(items.managerScore);
        }
        return {};
      });
      if (sum >= 0) {
        return sum.toFixed(2);
      }
      return "";
    },

    getKPATotalManagerAppraisal(kpaTypesname) {
      let appraisalVal;
      this.employeeAppraisals.filter((appraisal) => {
        if (appraisal.cycle === this.activeTab) {
          appraisal.summary.filter((summary) => {
            if (summary.kpaTypeName === kpaTypesname) {
              appraisalVal = summary.managerFlag;
            }
            return {};
          });
        }
        return {};
      });

      return appraisalVal || "---";
    },

    getKPATotalSelfAppraisal(kpaTypesname) {
      let appraisalVal;
      this.employeeAppraisals.filter((appraisal) => {
        if (appraisal.cycle === this.activeTab) {
          appraisal.summary.filter((summary) => {
            if (summary.kpaTypeName === kpaTypesname) {
              appraisalVal = summary.employeeFlag;
            }
            return {};
          });
        }
        return {};
      });
      return appraisalVal || "---";
    },

    submitAppraiser(state, finalAppraisal) {
      const kpaData = [];
      const validateKPA = [];
      this.payload.orgId = this.$orgId;
      this.payload.appraisal_status = "pending";
      this.payload.year = this.$route.params.year;
      this.payload.employeeId = this.$route.params.userId;
      this.payload.appraisalFor = this.$route.params.userId;

      if (finalAppraisal) {
        this.payload.appraisal_agreement = "employee_agreed";
      }

      if (state === "draft") {
        this.payload.status = state;
      } else {
        this.payload.status = "active";
        this.payload.share = true;
      }
      this.templateKPATypes.forEach((kpaType) => {
        const newKPAs = kpaType.kpas.map(
          ({
            name,
            objective,
            kpis,
            weight,
            selfRating,
            selfScore,
            managerRating,
            managerScore,
            ...rest
          }) => ({
            ...rest,
          })
        );
        const validation = newKPAs.every((item) => item.ratingId);
        validateKPA.push(validation);
        kpaData.push({
          kpa_types_id: kpaType.id,
          kpas: newKPAs,
        });
      });

      if (validateKPA.every((valid) => valid === true)) {
        this.payload.kpa_types = kpaData;
        this.$_setManagerAppraisal(this.payload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
            this.$router.back();
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      } else {
        this.$toasted.error("No scores set for each KPA", { duration: 5000 });
      }
    },

    checkSelected(value) {
      if (value === "Approve") {
        this.approveAppraisal();
      } else {
        this.submitAppraiser("active", this.finalAppraisal);
      }
    },

    approveAppraisal() {
      const requestPayload = {
        appraisalId: this.payload.appraisalId,
        appraisal_status: "submitted",
      };
      this.$_approveAppraisal(requestPayload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.$router.back();
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    getUserDetails() {
      this.$_getOneEmployeeAll({
        id: this.$route.params.userId,
      }).then((response) => {
        this.employees.push({
          id: response.data.userId,
          name: `${response.data.fname} ${response.data.lname}`,
          photo: response.data.photo,
          designation: response.data.userDesignation.name,
          function: response.data.orgFunction.name,
          manager: `${response.data.userReportingTo.fname} ${response.data.userReportingTo.lname}`,
          employeeId: response.data.employeeId,
          office: response.data.employeeOffice.name,
        });

        this.isLoading = false;
      });
    },

    getEmployeeAppraisal() {
      this.employeeAppraisals = [];
      this.selfAppraisalScore = "";
      this.lineManagerAppraisalScore = "";
      this.approvedScore = "";

      this.$_getEmployeeAppraisal(this.$route.params.userId).then((result) => {
        const responseData = result.data.EmployeeAppraisal;

        responseData.forEach((response) => {
          if (response.appraisal.current_cycle) {
            if (this.finalAppraisal) {
              if (response.appraisal.current_cycle.name === this.activeTab) {
                this.selfAppraisalScore = response.appraisal.self_score;
                this.lineManagerAppraisalScore =
                  response.appraisal.manager_score;
                this.approvedScore = response.appraisal.approved_score;
                this.appraisalStatus = response.appraisal.appraisal_status;
                this.appraisalState = response.appraisal.appraisal_state;
                this.payload.appraisalId = response.appraisal.id;
                this.appraisalAgreed = response.appraisal.appraisal_agreement;
              }
            }

            if (!this.finalAppraisal) {
              if (response.appraisal.current_cycle.name === this.activeTab) {
                this.selfAppraisalScore =
                  response.appraisalSummary.employeeFlag;
                this.lineManagerAppraisalScore =
                  response.appraisalSummary.managerFlag;
                this.approvedScore = response.appraisalSummary.managerFlag;
                this.appraisalStatus = response.appraisal.appraisal_status;
                this.appraisalState = response.appraisal.appraisal_state;
                this.payload.appraisalId = response.appraisal.id;
              }
            }

            response.appraisal.performance_scores.map((score) => {
              this.employeeAppraisals.push({
                cycle: response.appraisal.current_cycle.name,
                id: score.kpaId,
                selfRatingPoint: score.selfRatingPoint,
                flagId: score.ranking ? score.ranking.flag.name : "",
                managerFlag: score.manager_ranking
                  ? score.manager_ranking.flag.name
                  : "",
                selfScore: score.selfScore,
                outcome: score.outcome,
                managerRatingPoint: score.managerRatingPoint,
                managerScore: score.managerScore,
                summary: response.summary,
              });
              return {};
            });
          }
        });

        this.getAgreements();
      });
    },

    formatEmployeeDate(value) {
      let initialDate = "";
      if (value) {
        initialDate = new Date(value).toISOString().replace(/.\d+Z$/g, "");
        return this.$DATEFORMAT(new Date(initialDate), "MMMM dd, yyyy");
      }
      return "";
    },

    getAgreements() {
      this.$_getEmployeeAgreement(this.$route.params.userId).then(
        (response) => {
          const myAgreements = response.data.EmployeePerformanceAgreements;

          myAgreements.map((agreement) => {
            if (Number(agreement.year) === Number(this.$route.params.year)) {
              const agreementKPA = agreement.employee_agreement_kpa;

              this.payload.employeeAgreementId = agreement.id;
              this.payload.goalId = this.$route.params.goalId;

              this.$_getOneTemplate(agreement.templateId).then((result) => {
                this.templateKPATypes =
                  result.data.PerformanceTemplate.kpa_types;

                this.templateKPATypes.forEach((kpaType) => {
                  // eslint-disable-next-line no-param-reassign
                  kpaType.kpas = [];
                  agreementKPA.filter((employeeKpi) => {
                    if (employeeKpi.kpaTypeId === kpaType.id) {
                      kpaType.kpas.push({
                        name: employeeKpi.name,
                        objective: employeeKpi.objective,
                        kpaId: employeeKpi.id,
                        kpis: this.getCriteriaData(employeeKpi.kpis_employee),
                        weight: employeeKpi.weight,
                        outcome: this.getOutcome(employeeKpi.id),
                        selfRating: this.getSelfRating(employeeKpi.id),
                        selfScore: this.getScore(employeeKpi.id, employeeKpi),
                        ratingId: this.getRatingId(employeeKpi.id),
                        managerRating: this.getRatingId(employeeKpi.id),
                        managerScore: this.getManagerScore(
                          employeeKpi.id,
                          employeeKpi
                        ),
                      });
                    }
                    return {};
                  });
                });
              });

              this.loaded = true;
              this.loadingAppraisal = false;
            }
            return {};
          });

          setTimeout(() => {
            this.isLoading = false;
          }, 1000);

          if (this.allComments.length === 0) {
            this.getComments(this.payload.employeeAgreementId);
          }
        }
      );
    },

    getScore(kpasid) {
      let score;
      this.employeeAppraisals.filter((appraisal) => {
        if (appraisal.id === kpasid && appraisal.cycle === this.activeTab) {
          score = appraisal.selfScore;
        }
        return {};
      });
      return score || "";
    },

    getSelfRating(kpasid) {
      let ratePoint;
      let rating;

      this.employeeAppraisals.filter((appraisal) => {
        if (appraisal.id === kpasid && appraisal.cycle === this.activeTab) {
          if (this.finalAppraisal) {
            ratePoint = appraisal.selfRatingPoint;
          } else {
            ratePoint = appraisal.flagId;
          }
        }
        return {};
      });

      if (this.finalAppraisal) {
        this.ratingOptions.filter((ratings) => {
          if (ratings.name === ratePoint) {
            rating = ratings.id;
          }
          return {};
        });
      } else {
        this.flagOptions.filter((ratings) => {
          if (ratings.name === ratePoint) {
            rating = ratings.id;
          }
          return {};
        });
      }
      return rating || "";
    },

    getOutcome(kpasid) {
      let outcome;
      this.employeeAppraisals.filter((appraisal) => {
        if (appraisal.id === kpasid && appraisal.cycle === this.activeTab) {
          outcome = appraisal.outcome;
        }
        return {};
      });
      return outcome || "";
    },

    getManagerScore(kpasid) {
      let score;
      this.employeeAppraisals.filter((appraisal) => {
        if (appraisal.id === kpasid && appraisal.cycle === this.activeTab) {
          score = appraisal.managerScore;
        }
        return {};
      });
      return score || "";
    },

    getRatingId(kpasid) {
      let ratePoint;
      let rating;

      this.employeeAppraisals.filter((appraisal) => {
        if (appraisal.id === kpasid && appraisal.cycle === this.activeTab) {
          if (this.finalAppraisal) {
            ratePoint = appraisal.managerRatingPoint;
          } else {
            ratePoint = appraisal.managerFlag;
          }
        }
        return {};
      });

      if (this.finalAppraisal) {
        this.ratingOptions.filter((ratings) => {
          if (ratings.name === ratePoint) {
            rating = ratings.id;
          }
          return {};
        });
      } else {
        this.flagOptions.filter((ratings) => {
          if (ratings.name === ratePoint) {
            rating = ratings.id;
          }
          return {};
        });
      }
      return rating || "";
    },

    getCriteriaData(kpis) {
      const criteriaData = [];
      const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);
      const sortedKPIS = kpis.sort((a, b) => a.score.rating - b.score.rating);
      this.Ratings = sortedRatings;
      // eslint-disable-next-line no-param-reassign
      kpis = sortedKPIS;
      this.Ratings.map((rating, index) => {
        criteriaData.push({
          id: kpis[index].id,
          performanceRatingId: rating.id,
          criteria: kpis[index].criteria,
        });
        return {};
      });
      return criteriaData;
    },

    checkAppraisalStart() {
      const today = new Date();

      this.appraisalEndDate.setDate(this.appraisalEndDate.getDate() + 1);

      if (today >= this.appraisalStartDate && today <= this.appraisalEndDate) {
        this.startAppraisal = true;
      } else if (
        today < this.appraisalStartDate &&
        today <= this.appraisalEndDate
      ) {
        this.startAppraisal = false;
        this.appraisalMessage = `${this.activeTab} appraisal starts on the
                          ${this.$DATEFORMAT(
          new Date(this.appraisalStartDate),
          "MMMM dd, yyyy"
        )}. You will be notified when it begins.`;
      } else {
        this.startAppraisal = true;
        this.appraisalMessage = "";
        this.appraisalEnd = true;
      }

      this.getEmployeeAppraisal();
    },

    getCycleTabs() {
      this.tabsItems = [];

      this.$_getAppraisalCycle(this.appraisalQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;

        const appraisalCycle = appraisal[0].cycles.sort(
          (a, b) =>
            this.setDates(a.appraisal_starts) -
            this.setDates(b.appraisal_starts)
        );
        this.tabsItems = appraisalCycle.map(({ name }) => name);
      });
    },

    getSettings() {
      this.$_getPerformanceGeneralSetting().then((response) => {
        response.data.PerformanceSettings.map((setting) => {
          const appraisalByWho = setting.appraisailScoringPreference;
          this.checkEditAppraisal = setting.managerCanEditEmployeeAppraisal;
          if (appraisalByWho === "manager_to_appraisee") {
            this.appraisalDisplay = "manager";
            this.KPAHeader.push(
              {
                title: "First Line Manager’s Rating",
                value: "managerRating",
              },
              {
                title: "Performance Outcome",
                value: "outcome",
              }
            );
          } else if (appraisalByWho === "employee_self_appraisal") {
            this.appraisalDisplay = "employee";
            this.KPAHeader.push(
              {
                title: "Self Rating",
                value: "selfRating",
              },
              {
                title: "Performance Outcome",
                value: "outcome",
              }
            );
          } else if (appraisalByWho === "both") {
            this.appraisalDisplay = "both";
            this.KPAHeader.push(
              {
                title: "Self Rating",
                value: "selfRating",
              },
              {
                title: "First Line Manager’s Rating",
                value: "managerRating",
              },
              {
                title: "Performance Outcome",
                value: "outcome",
              }
            );
          }
          return {};
        });
      });
    },

    getUser() {
      this.$_getUser(this.$AuthUser.id).then((result) => {
        this.loadingData = false;
        if (result.data.user.userDesignation.reportingTo !== null) {
          this.employee = true;
        } else {
          this.employee = false;
        }
      });
    },

    formatCommentTime(date) {
      const newDate = new Date(date);
      const hours = newDate.getUTCHours();
      const minutes = newDate.getMinutes();
      return `${1 + ((hours - 1) % 12)}:${minutes
        .toString()
        .padStart(2, "0")} ${hours > 11 ? "PM" : "AM"}`;
    },

    getComments(agreementId) {
      this.allComments = [];
      if (agreementId) {
        this.$_getAgreementComments(agreementId).then((response) => {
          const { comments } = response.data;

          this.allComments = comments.map((comment) => ({
            message: comment.comment,
            time: this.formatCommentTime(comment.date),
            commentId: comment.id,
            isRead: comment.readStatus,
            receiver: comment.receiver,
            sender: comment.sender,
          }));

          this.loadComments = false;
        }).catch((error) => {
          this.loadComments = false;
        });
      }
    },

    async sendComment() {
      try {
        await this.$handlePrivilege(
          "performanceAppraisals",
          "makeComment"
        );
        const payload = {
          performanceAgreementId: this.payload.employeeAgreementId,
          senderId: this.$AuthUser.id,
          receiverId: this.$AuthUser.id,
          comment: this.comment,
          sendTo: "admin",
        };
        this.$_postAgreementComments(payload).then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getComments(this.payload.employeeAgreementId);
          this.comment = "";
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    markAsRead(commentId) {
      const payload = {
        commentId,
        readStatus: true,
      };
      this.$_markAsRead(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getComments(this.payload.employeeAgreementId);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    getAppraisalFlags() {
      this.$_getAppraisalFlag().then((response) => {
        const { flags } = response.data;
        flags.forEach((flag) => {
          this.flagOptions.push({
            id: flag.id,
            name: flag.name,
          });
        });
      });
    },
  },

  async mounted() {
    try {
      await this.$handlePrivilege(
        "performanceAppraisals",
        "viewEmployeeAppraisal"
      );
      this.hasModuleAccess = true;

      if (this.$route.params.user === this.$AuthUser.id) {
        this.payload.appraisalFor = this.$AuthUser.id;
        this.payload.appraisalBy = this.$AuthUser.id;
        this.payload.employeeId = this.$AuthUser.id;
      } else {
        this.payload.appraisalFor = this.$route.params.user;
        this.payload.appraisalBy = this.$AuthUser.id;
        this.payload.employeeId = this.$route.params.user;
      }

      this.$_getPerfomanceRating().then((response) => {
        this.Ratings = response.data.PerformanceRatings;

        response.data.PerformanceRatings.map((ratings) => {
          this.ratingOptions.push({
            id: ratings.id,
            name: ratings.rating,
          });
          return {};
        });
      });
      this.getSettings();
      this.getCycleTabs();
      this.getUser();
      this.getAppraisalFlags();

      this.getUserDetails();
    } catch (error) {
      this.hasModuleAccess = false;
    }
  },
};
</script>

<style>
.input-select {
  border: 1px solid #878e99;
  height: 38px;
  padding: 10px 15px;
  width: 100%;
  border-radius: 5px;
  color: #321c3b;
  line-height: 120%;
  letter-spacing: 0.015em;
}

.input-select:focus {
  outline: none;
}

.input-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(/img/down-arrow.f3bc3f05.svg);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 14px;
  padding: 3px 15px;
  width: 100%;
  padding-right: 29px;
  outline: none;
  border: 1px solid #878e99;
  box-sizing: border-box;
  border-radius: 3px;
  height: 40px;
  /* width: 100%; */
  /* width: fit-content; */
}
</style>
